import React from "react";
import ReactPlayer from 'react-player'

const TribeticSection7 = (props) => {
    return(
        <section className="section-7">
            <div className="container">
                <div className="title-and-description-block">
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-13">
                            <h1 className="h1 section-title">{props.mainTitle}</h1>
                        </div>
                        <div className="col-lg-8"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-19">
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-22">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="video-block">
                                    <ReactPlayer 
                                        url={props.VideoUrl} 
                                        width='100%' 
                                        height="100%"
                                        loop={true}
                                        playing={true}
                                        muted={true}
                                        playsinline={true}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-1"></div>
                            <div className="col-lg-14">
                            <div className="image-block">
                                <img
                                    src={props.image1x?.sourceUrl}
                                    srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                                    width={props.image1x?.width}
                                    alt={props.image1x?.altText}
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            </div>
        </section>
    )
}

export default TribeticSection7;